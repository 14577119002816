import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';

// import { ShimmerProvider } from './components/ShimmerContext';

import { MoonLoader} from 'react-spinners';

import { GoogleOAuthProvider } from '@react-oauth/google';
const AppLoad = lazy(() => import('./App'));

ReactDOM.render(
  <React.StrictMode>
    {/* <ShimmerProvider> */}
    <Suspense fallback={<></>}>
      <GoogleOAuthProvider clientId="835422974724-4gfks276e35eouulgurm686bkp2vvgri.apps.googleusercontent.com">
        <AppLoad />
      </GoogleOAuthProvider>
    </Suspense>
    {/* </ShimmerProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);
